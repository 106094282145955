// Resets

// Dependencies
@import 'variables';
//@import "../components/element/variables";

@import 'bootstrap-4.1.3/bootstrap';
@import 'bootstrap-4.1.3/rtl-bootstrap.scss';
//@import "../components/element/bootswatch";
@import '../components/element/elementStyle.scss';
@import 'fonticon';
@import 'mixin';
@import 'media';
@import 'reset';
@import 'button';
@import 'lang';

// Global Codes
@import 'main';
@import 'ltr';

// Pages
@import "./pages/index";

// Components

@import 'components/header';
@import 'components/sidebar';
@import 'components/footer';
@import 'components/pagination';
@import 'components/switch';
@import 'components/datepicker';

@import './transaction';
//@import 'components/confirmModal';
