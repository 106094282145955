@import 'variables';

body {
  //background-color: $darkblue;
}

form {
  label {
    display: block;
  }
}

.table {
  background-color: #fff;
  header {
    display: flex;
    border-bottom: 1px solid #9e9e9e;
    padding: 8px 0;
    .col {
      flex: 1;
      padding: 4px;
    }
    .col2 {
      flex: 2;
      padding: 4px;
    }
  }
  .tblRow {
    display: flex;
    .col {
      flex: 1;
      padding: 4px;
    }
    .col2 {
      flex: 2;
      padding: 4px;
    }
  }
}

.text-primary {
  color: $primary;
}

button:focus {
  outline: 0 auto transparent;
}

.error-alert {
  width: 100%;
  font-size: 80%;
  color: #dc3545;
}

.main {
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: $background;
  display: flex;
  flex-direction: column;
  height: 100%;

  .main-footer {
    text-align: center;
    flex: 1 0 auto;
    min-height: auto;
    padding: 10px 10px 0 10px;
    width: 100%;
    color: $white;

    a {
      color: $silver;
    }
  }
  .main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: auto;
    padding: 30px 35px 10px 35px;

    .contentWrapper {
      margin-top: 4.5rem;

      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
      }
    }

    @include themify() {
      background-color: themed('page-background');
    }
    transition: all 0.3s;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 100%;
    min-height: 95vh;

    .titleBar {
      margin-top: 30px;
      h6 {
        font-size: 18px;
        margin-top: 8px;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-top: 100px;
      padding: 10px 20px 10px 20px;
      border-radius: 25px 25px 0 0;
    }

    @include breakpoint(medium) {
      width: calc(100% - #{$width-sidebar});
    }
    @include breakpoint(ultra) {
      padding: 30px 80px 20px 80px;
    }

    .d-block-mobile {
      @include breakpoint(xsmall) {
        display: block !important;
        margin-bottom: 5px;
      }
    }

    .emptyState {
      text-align: center;
      color: $silver;
      padding: 40px 20px;
      img {
        width: 76px;
        margin-bottom: 20px;
      }
    }
    input {
      //&.form-control {
      //  box-shadow: none;
      //  border-radius: 0;
      //  border: 0;
      //  border-bottom: 1px solid #666;
      //  @include media-breakpoint-down(sm) {
      //    font-size: 12px;
      //    padding: 4px 1px;
      //  }
      //}
      &.price {
        box-shadow: none;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #ced4da;
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        display: block;
        height: calc(2.25rem + 4px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        @include media-breakpoint-down(sm) {
          font-size: 12px;
          padding: 4px 1px;
        }
        &:hover {

        }
      }
    }

    .main-wrapper {
      flex: 1 1 100%;
      margin: 4.5rem 0 60px 0;

      .photoGallery {
        div {
          border-bottom: 1px dotted #f5f5f5;
          padding: 5px 0;
          img {
            width: 64px;
          }
          .icon-bin {
            cursor: pointer;
          }
        }
      }
      //input {
      //  &.form-control {
      //    border: 0;
      //    border-radius: 0;
      //    border-bottom: 1px solid #ced4da;
      //    box-shadow: none;
      //  }
      //}
      //textarea {
      //  &.form-control {
      //    border: 0;
      //    border-radius: 0;
      //    border-bottom: 1px solid #ced4da;
      //    box-shadow: none;
      //  }
      //}

      .css-1pahdxg-control {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #ced4da;
        box-shadow: none;
        &:hover {
          border: 0;
          border-bottom: 1px solid $secondary;
          border-radius: 0;
        }
        .css-1okebmr-indicatorSeparator {
          background-color: transparent;
        }
      }
      //.css-yk16xz-control {
      //  border-radius: 0;
      //  border: 0;
      //  border-bottom: 1px solid #ced4da;
      //  &:hover {
      //    border: 0;
      //    border-bottom: 1px solid $secondary;
      //    border-radius: 0;
      //  }
      //  .css-1okebmr-indicatorSeparator {
      //    background-color: transparent;
      //  }
      //}
    }

    .grid-view {
      margin-top: 5px;
      .MuiPaper-root {
        background: transparent;
        & > div:nth-child(2n + 2) {
          border-radius: 15px;
          background-color: #fff;
          padding: 20px;
          border: 1px solid $border;
          .MuiTable-root {
            .MuiTableHead-root {
              tr {
                border-radius: 10px;
                .MuiTableCell-root {
                  z-index: 0;
                  background-color: #f7f8f9 !important;
                  padding: 5px;
                }
              }
            }
          }
        }
        .MuiTable-root {
          .MuiTableCell-root {
            border: 0;
            .icons {
              display: flex;
              justify-content: center;
              flex-direction: row-reverse;
              margin-top: 10px;
              a {
                text-decoration: none;
                margin: 0 7px;
              }
              i {
                cursor: pointer;
                &:before {
                  color: $gray-500;
                }
              }
            }

            h5 {
              @include media-breakpoint-down(sm) {
                font-size: 12px;
                font-weight: bold;
              }
            }
          }
        }
        .MuiFormControl-root {
          background: #fff;
          border-radius: 10px;
          border: 1px solid $border;
          .MuiInput-underline {
            &:before {
              border-bottom: 0;
            }
            &:after {
              border-bottom: 0;
            }
          }
          .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 0;
          }
          input {
            box-shadow: unset;
          }
        }
        .MuiToolbar-root {
          padding: 2px;
          h6 {
            font-weight: bold;
            font-size: 14px;
            @include media-breakpoint-down(sm) {
              font-size: 13px;
            }
          }
        }
        .inline-toolbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
          }
          .custom-toolbar {
            display: flex;
            align-items: baseline;
            flex-basis: 60%;
            justify-content: space-between;
            @include media-breakpoint-down(sm) {
              flex-basis: 100%;
            }
            button {
              i {
                display: none;
                @include media-breakpoint-down(sm) {
                  display: inline-block;
                }
              }
              span {
                display: inline-block;
                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }
            }

          }
          .MuiToolbar-root {
            display: inline-flex;
          }
          .MuiToolbar-gutters {
            padding: 0;
          }
        }
      }
    }
    .show-panel {
      background-color: white;
      margin: 0 1rem 1rem;
      border-radius: 15px;
      border: 1px solid $border;
      min-height: 65vh;

      & > h6 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 20px 0;
      }
      hr {
        border: 0;
        border-bottom: 1px dashed $silver;
        margin: 32px 0;

        &.white {
          border-bottom: 1px solid white;
          margin: 24px 0;
        }
      }
    }
    .show-widget {
      padding: 20px;
      margin: 5px;
      border-radius: 15px;

      &.smallToolbar {
        .MuiToolbar-gutters {
          min-height: auto;
          h6 {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
      .MuiPaper-root {
        & > div:nth-child(2n + 2) {
          padding: 20px 0;
        }
      }
    }
    .hideToolbar {
      .MuiToolbar-gutters {
        display: none;
      }
    }
    .MuiPaper-elevation2 {
      box-shadow: none;
      .MuiTypography-h6 {
        font-size: 14px;
      }
    }
  }
}

.main-container {
  [class*='css-'] {
    [class$='-control'] {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #ced4da;
      box-shadow: none;
      &:hover {
        border: 0;
        border-bottom: 1px solid $secondary;
        border-radius: 0;
      }
      [class$='-indicatorSeparator'] {
        background-color: transparent;
      }
    }
  }
  input.form-control {
    box-shadow: none;
    border-radius: 0;
  }
}

.main-header{
  min-width: 8rem;
  position: absolute;
  left: 1rem;
}
.ltr .main-header{
  left: unset;
  right: 1rem;
}
.button-container {
  span {
    margin: 0 5px;
    box-shadow: unset;
  }

  button {
    margin: 0;
  }
}

.page-loader {
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  align-items: center;
}

.loading-wrapper {
  width: 100%;
  @include themify() {
    background-color: themed('page-background');
  }

  em {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.5);
  }

  @keyframes loading-dots-animation {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 10px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .loading-dots {
    display: flex;
    position: relative;
    .dot {
      width: 10px;
      height: 10px;
      margin: 0 4px;
      border-radius: 10px;
      background-color: #e8f4fb;
    }
    .dot:nth-last-child(2) {
      animation: loading-dots-animation 0.6s 0.1s linear infinite;
    }
    .dot:nth-last-child(3) {
      animation: loading-dots-animation 0.6s 0.2s linear infinite;
    }
    .dot:nth-last-child(4) {
      animation: loading-dots-animation 0.6s 0.3s linear infinite;
    }

    p {
      display: inline-block;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 30px);
      color: $gray-200;
    }
  }
}

.relative-pos {
  position: relative !important;
}

.input-group > select.form-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ced4da;
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-pos {
  position: fixed;
  @include breakpoint(large) {
    position: unset;
  }
}
.border-box {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px;
}
.alert {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.Toastify__toast {
  &.Toastify__toast--warning {
    background-color: $secondary;
  }
  &.Toastify__toast--info {
    background: $primary;
  }
  &.Toastify__toast--success {
    background: $primary;
  }
  &.Toastify__toast--error {
    background-color: $secondary;
  }
}

.Toastify .Toastify__toast-body {
  text-align: center;
}

.link-button-transparent {
  display: block;
  cursor: pointer;
  border: none;
  width: 100%;
  padding: 0;
  background-color: transparent;

  div:first-child {
    border: 1px solid $border;
  }
  &.active div:first-child {
    border: 2px solid $primary;
  }
}

// overwrite material table style
tr.MuiTableRow-root {
  display: none;
}
tr.MuiTableRow-root.MuiTableRow-head,
tr.MuiTableRow-root.MuiTableRow-footer,
.MuiTableRow-root[level] {
  display: table-row;
}
.MuiTableCell-root {
  padding: 8px !important;
}
.MuiIconButton-root.Mui-disabled {
  visibility: hidden;
}
.MuiLinearProgress-colorPrimary {
  background-color: transparent !important;
  margin: -3px 10px 0;
  border-radius: 2px;
}
.MuiLinearProgress-barColorPrimary {
  background-color: $primary !important;
}
.unread {
  font-weight: 600;
}
.messageDate {
  font-size: 12px;
  text-align: right;
}
.sidebar-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: none;
  cursor: pointer;
}
.collapsed-sidebar .sidebar-cover {
  display: block;
}

[class^='modal---modal-overlay'] {
  .hoursModal {
    &[class*='modal---modal-content'] {
      max-width: max-content !important;
      [class*='modal---modal-body---'] {
        padding: 16px 0 !important;
        @include media-breakpoint-down(sm) {
          padding: 16px 5px !important;
        }
      }
    }
  }
}

.tether-element {
  z-index: 9999;
}
.bg-dark {
  background-color: $secondary;
}
.bg-gold {
  background-color: #c09d61;
}

.btn {
  @include media-breakpoint-down(sm) {
    font-size: 13px !important;
    padding: 8px 10px !important;
  }
}

.hidden-sm {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.mobilePreview {
  display: none;
  @include media-breakpoint-down(sm) {
    display: inline-block;
  }
}

.modalFooter {
  .btn {
    @include media-breakpoint-down(sm) {
      min-width: auto;
    }
  }
  .modalMore {
    margin-right: unset !important;
    @include media-breakpoint-down(sm) {
      font-size: 11px;
      flex-basis: 40%;
    }
  }
}

.logoContainer {
  @include media-breakpoint-down(sm) {
    .col-md-2 {
      width: 19.9%;
    }
    .col-md-10 {
      width: 79.9%;
    }
  }
}

.MuiTablePagination-root {
  @include media-breakpoint-down(sm) {
    padding: 8px 13px !important;
  }
}


.announcementBtnGroup {
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    button {
      width: 40%;
      font-size: 10px !important;
    }

    div {
      padding: 18px;
      font-size: 10px;
    }
  }
  div {
    padding: 24px 15px;
  }
}

.text-help {
  font-size: 12px;
  color: #888;
}

#formAwards {
  .button-container {
    width: 100%;
    justify-content: flex-end;
  }
}

#productsForm {
  .button-container {
    width: 100%;
    justify-content: flex-end;
  }
  .productRow {
    &:hover {
      background: lighten($primary, 40%);
      .form-control {
        background: transparent;
      }
      .price {
        background: transparent;
      }
    }
  }
}

.flexSmColumn {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.announcementPreviewImg {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

#formDefinitions {
  .input-group {
    align-items: baseline;
    input.form-control {
      min-width: 40px;
      max-width: 60px;
      padding: 5px;
      margin: 0 5px;
      line-height: 1;
      height: auto;
    }
  }
}

#chartPlaceholder {
  width: 100%;
  height: 350px;
  text-align: center;

  div {
    margin-bottom: 32px;
    &.description {
      color: $gray-500;
    }
  }

  img {
    height: 250px;
    margin-bottom: -75px;
  }
}

table.productList {
  width: 100%;
  th {
    padding: 5px;
    text-align: center;
  }
  td {
    padding: 7px;
  }
  .invoiceItems {
    td {
      border-left: 1px dotted #dedede;
      text-align: center;
      &:first-child {
        border-right: 1px dotted #dedede;
      }
    }
  }
  .topBorder {
    td {
      border-top: 1px dotted #dedede;
    }
  }
  .bottomBorder {
    th {
      border-bottom: 1px dotted #dedede;
    }
  }

}

.bold {
  font-weight: bold;
}

.qtyCol {
  width: 25%;
}

.ltrSign {
  direction: ltr;
}

.maxWidth {
  display: inline;
  min-width: 250px;
}

.Width50 {
  width: 50px;
}

.Width100 {
  width: 100px;
}

.noBorder {
  border: 0px;
  border-bottom: 0px;
}

@media print {
  body {
    *  {
      visibility: hidden;
    }
    .info-page__box-left {
      .box-end  {
        visibility: visible;
        position: fixed;
        top: 0;
        height: auto;
        min-height: auto;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        .no-print {
          visibility: hidden;
        }
        > div:first-child {
          border: 1px solid #888;
          margin: auto;
          * {
            visibility: visible;
            .no-print {
              visibility: hidden;
            }
          }
        }
        button {
          visibility: hidden;
          * {
            visibility: hidden;
          }
        }
        .div-end {
          position: relative;
        }
      }
    }
  }
}

img.round {
  border-radius: 7px;
}

.cursor-pointer {
  cursor: pointer;
}

@for $i from 10 through 50 {
  .circle-#{$i} {
    width: #{$i}px;
    height: #{$i}px;
    line-height: #{$i}px;
    border-radius: 50%;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.content-margin {
  margin-top: $content-margin;
}

.full-width{
  width: 100%;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.container-wrapper {
  //margin-top: 4.5rem;
}

@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}

.directionLtr {
  direction: ltr;
}

.alert-primary {
  background: lighten($primary, 30%) !important;
}

.MuiFormControl-root {
  background: #fff;
  border-radius: 10px;
  border: 1px solid $border;
  .MuiInput-underline {
    &:before {
      border-bottom: 0;
    }
    &:after {
      border-bottom: 0;
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  input {
    box-shadow: unset;
  }
}
.MuiToolbar-root {
  padding: 2px;
}

.mt-2r {
  margin-top: 2rem;
}
.content-nMargin {
  margin-top: -4.5rem;
}

.align-center {
  text-align: center;
}

.rtl .margin-50 {
  margin-right: 50%;
}

.ltr .margin-50 {
  margin-left: 50%;
}

.text-info {
  color: $primary !important;
}
a.text-info:hover, a.text-info:focus {
  color: darken($primary, 10%) !important;
}


.card {
  box-shadow: 0 1px 4px rgb(0,0,0 / 40%);
}
.dButton {
  box-shadow: 0 1px 4px rgb(0,0,0 / 40%);
}

button[class^="btn-outline-"],
button[class*=" btn-outline-"],
button[class^="btn-text-"],
button[class*=" btn-text-"] {
  box-shadow: none !important;
}

.sidebar-hr {
  border: none;
  height: 24px;
}

.sidebar-name {
  font-size: 16px !important;
}

.dSwitch.primary {
  &.active {
    .react-switch-handle {
      transform: translateX(15px) !important;
      background: $prime !important;
      background-color: $prime !important;
    }
  }

  .react-switch-handle {
    height: 15px !important;
    width: 15px !important;
    top: 5px !important;
    left: 5px !important;
  }
  .react-switch-bg {
    width: 35px !important;
  }
}
